.hero {
  background: var(--background-primary);
  margin-bottom: -20px;
  padding: 1em;
}

.hero .hero-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding-top: 1em;
  display: flex;
}

.hero .hero-content img {
  z-index: 1;
  position: relative;
}

@media screen and (min-width: 821px) {
  .hero .hero-content {
    flex-direction: row;
    padding-top: 3em;
    display: flex;
  }
}

.hero .hero-text {
  width: 100%;
}

@media screen and (min-width: 821px) {
  .hero .hero-text {
    width: 50%;
    padding: 0 2em;
  }
}

.hero-bottom {
  position: relative;
}

.hero-bottom svg {
  position: absolute;
  top: 0;
}

@media screen and (min-width: 821px) {
  .hero-bottom svg {
    position: absolute;
    top: -40px;
  }

  .hero-bottom svg:nth-child(2) {
    position: absolute;
    top: -80px;
  }
}

/*# sourceMappingURL=index.098fff2b.css.map */
